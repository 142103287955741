// Author: 劉喆 John Liu
// License: GNU General Public License Version 3 (GPLv3)

import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import sessionStorage from 'redux-persist/lib/storage/session';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';


const appPersistConfig = {
  key: 'app',
  storage: storage,
  blacklist: ['cardSide'],
}

const flashcardPersistConfig = {
  key: 'flashcard',
  storage: sessionStorage,
  stateReconciler: hardSet,
};



interface AppState {
  scrollPosition: number;
  randomState: boolean;
  writeState: boolean;
  userList: any;
  publisher: string;
  year: string;
  set: string;
  subset: string;
  speakerState: boolean;
  cardSide: boolean;
  currentSet: any;
  currentMode: string;
  canvasVisible: boolean;
  isPlaying: boolean;
  modes: string[];
}

const initialState: AppState = {
  scrollPosition: 0,
  randomState: false,
  writeState: false,
  userList: [],
  publisher: '南一',
  year: '',
  set: '',
  subset: '',
  speakerState: true,
  cardSide: false,
  currentSet: [],
  currentMode: '學',
  canvasVisible: false,
  isPlaying: false,
  modes: ['學', '練', '寫'],
};


function appReducer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_IS_PLAYING':
      return {...state, isPlaying: action.payload };
    case 'SET_CANVAS_VISIBILITY':
      return {...state, canvasVisible: action.payload };
    case 'UPDATE_SCROLL_POSITION':
      return { ...state, scrollPosition: action.payload };
    case 'TOGGLE_RANDOM_STATE':
      return { ...state, randomState: !state.randomState };
    case 'TOGGLE_WRITE_STATE':
      return { ...state, writeState: !state.writeState };
    case 'SET_CURRENT_SET':
      return { ...state, currentSet: action.payload };
    case 'ADD_TO_USER_LIST':
      return { ...state, userList: [...state.userList, action.payload] };
    case 'REMOVE_FROM_USER_LIST':
      return { ...state, userList: state.userList.filter((card: any) => card.fields.side1 !== action.payload.fields.side1) };
    case "SET_SPEAKER_STATE":
      return { ...state, speakerState: !state.speakerState };
    case "SET_CARD_SIDE":
      return { ...state, cardSide: action.payload };
    case 'SET_PUBLISHER':
      return { ...state, publisher: action.payload };
    case "SET_YEAR":
      return { ...state, year: action.payload };
    case "SET_SET":
      return { ...state, set: action.payload };
    case "SET_SUBSET":
      return { ...state, subset: action.payload };
    case 'SET_CURRENT_MODE':
      const currentModeIndex = state.modes.indexOf(state.currentMode);
      const nextModeIndex = (currentModeIndex + 1) % state.modes.length;
      return {
        ...state,
        currentMode: state.modes[nextModeIndex], // Set the next mode
      };
    default:
      return state;
  }
}

const initialFlashcardState: any = {
    flashcarddata: 0,
}

function flashcardReducer(state = initialFlashcardState, action: any) {
  switch (action.type) {
    case 'RECEIVE_FLASHCARDDATA':
      return { ...state, flashcarddata: action.payload };
    case 'PURGE_FLASHCARDDATA':
      return {...state, flashcarddata: null };
    default:
      return state;
  }
}



const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  flashcard: persistReducer(flashcardPersistConfig, flashcardReducer),
})

const persistedReducer = rootReducer;

const store = createStore(persistedReducer);
const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>

export { store, persistor };
